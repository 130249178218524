.songs_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    margin-top: 20px;
}

.songs_list_wrapper {
    position: relative;
}

.songs_list_overlay {
    position: absolute;
    right: -2px;
    top: 0;
    height: 100%;
    width: 60px;
    /* I want the background to be an horizontal gradient from transparent to black */
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%,  rgba(0,0,0,0.5) 60%, rgba(0,0,0,1) 100%);
    z-index: 10;
}

.songs_list {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-x: scroll;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    position: relative;
}

.song {
    position: relative;
    width: 175px;
    aspect-ratio: 1;
    overflow: hidden;
}

.song_image {
    width: 100%;
    aspect-ratio: 1;
}

.song_infos {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    padding-top: 54%;
    box-sizing: border-box;
    background: linear-gradient(0deg, rgba(0,0,0,1) 4%, rgba(15,14,11,0.8715861344537815) 20%, rgba(193,154,70,0) 59%);
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.song_popu p {
    font-size: 30px;
    font-weight: bold;
}

.song_popu {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    padding-left: 15px;
}

.song_noms {
    flex: 1;
    overflow: hidden;
    margin-left: 10px;
}

.song_noms p {
    margin: 0;
}

.song_title {
    font-size: 17px;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 44px;
}

.song_artists {
    font-size: 15px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.song_duration {
    font-size: 17px;
    color: white;
}

.time_ranges {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 10px 0px;
    gap: 10px;
}

.time_range {
    padding: 5px 10px;
    border-radius: 3px;
    font-weight: 500;
    cursor: pointer;
}

.time_range.active {
    background: #22f16e;
    filter: drop-shadow(0 0 10px #1DB454);
    color: black;
}

.time_range.inactive {
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.8);
}

.songs_decennies {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
}

.songs_decennie {
    display: flex;
    align-items: center;
}

.songs_decennie p {
  color: white;
  margin: 0;
  margin-left: 10px;
  font-size: 17px;
}

.song_bar {
    height: 5px;
    box-shadow: 0 0 3px currentColor, 0 0 6px currentColor, 0 0 9px currentColor;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.popularity_div {
    background: #22f16e !important;
    filter: drop-shadow(0 0 10px #1DB454) !important;
}