.track_list {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
    max-width: 565px;
    padding: 20px;
    overflow: hidden;
    max-height: 85vh;
    overflow-y: scroll;
    background-color: rgba(255,255,255,0.8);
}

.track_decennies {
    display: flex;
    width: 250px;
    height: 150px;
    align-items: flex-end;
    margin-bottom: 80px;
}

.track_morePrecisions {
    font-size: 14px;
    opacity: 0.5;
    margin-top: 0;
    width: fit-content;
    margin-bottom: 20px;
    transition: 0.4s;
}


.track_morePrecisions:hover {
    opacity: 1;
    cursor: pointer;
}

.track_anim {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #F2F1DE;
    left: 0;
    animation: grow cubic-bezier(.76,.2,.36,.98) 1s forwards;
}

.track_anneeM {
    font-size: 26px;
    margin: 0;
    width: 270px;
}

@keyframes grow {
    0% {
        bottom: 0;
    }
    100% {
        bottom: 100%;
    }
}

.menu_choice {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 35px;
    transition: 0.4s;
    transform: scale(1);
}

.menu_choice:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.menu_choice img {
    width: 90px;
    background: white;
    height: 90px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.menu_choice p {
    margin: 0;
    margin-top: 5px;
    font-weight: 500;
}


.track_statName {
    padding-left: 12px;
    text-align: center;
    font-weight: 500;
}

.track_statAnim {
    width: 100%;
    height: 30px;
    position: absolute;
    background: #C2C1B2;
    top: 0;
    left: 0;
    animation: growLeft cubic-bezier(.76,.2,.36,.98) 1.5s 1.4s forwards;
    z-index: 1;
}

@keyframes growLeft {
    0% {
        left: 0;
    }
    100% {
        left: 100%;
    }
}


.track_display {
    width: 100%;
    box-sizing: border-box;
    min-height: 100vh;
    padding: 30px;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 130px;
    background: #F2F1DE;
}



.track_border {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
    overflow: hidden;
}

.track_time {
    display: flex;
    gap: 10px;
}

.track_time p {
    background: rgba(255,255,255,0.8);
    padding: 5px 10px;
    margin: 0;
    font-weight: bold;
    border-radius: 10px 10px 0px 0px;
    opacity: 1;
    transition: 0.4s;
}

.track_time p:hover {
    cursor: pointer;
}

.inactive {
    opacity: 0.6 !important;
    font-weight: 500 !important;
}

.track_list::-webkit-scrollbar {
    width: 10px;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
  }
  
  /* Track */
  .track_list::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
    overflow: hidden;
  }
   
  /* Handle */
  .track_list::-webkit-scrollbar-thumb {
    background: #888; 
    
  }

.track {
    position: relative;
    overflow: hidden;
    border-radius: 12px;
}

.track_image {
    object-fit: contain;
    aspect-ratio: 1/1;
    width: 175px;
}

.track_infos {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    padding-top: 60%;
    box-sizing: border-box;
    background: linear-gradient(0deg, rgba(0,0,0,1) 4%, rgba(15,14,11,0.8715861344537815) 20%, rgba(193,154,70,0) 59%);
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.track_popu p {
    font-size: 30px;
    font-weight: bold;
}

.track_popu {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    padding-left: 15px;
}

.track_noms {
    max-height: 60px;
    overflow: hidden;
    margin-left: 10px;
}

.track_noms p {
    margin: 0;
    font-weight: bold;
    font-size: 15px;
}

.track_artists {
    font-weight: normal !important;
}




@media only screen and (max-width: 1200px) {
    .track_display {
        flex-direction: column;
        gap: 50px;
    }
    .menu {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
        flex-direction: row;
    }
}

@media only screen and (max-width: 700px) {
    .menu {
        flex-wrap: wrap;
        gap: 0px;
    }
}



