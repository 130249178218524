.content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    padding-bottom: 100px;
    background: #292841;
}

.section_title {
    color: white;
    text-align: left;
    width: 100%;
    font-weight: 500;
    font-size: 24px;
    margin: 0;
    margin-top: 55px;
    margin-bottom: 7px;
}

.bottomNavigator {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 55px;
    background: rgba(0, 0, 0, 0.60);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(0, 0, 0, 0.3);
    z-index: 100;
}

.dividerr {
    width: 1px;
    height: 70%;
    background: rgba(255, 255, 255, 0.5);
    margin: 0 10px;
}

.iconContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}

.iconContainer:active {
    background: transparent;
}

.iconContainer:active, .iconContainer:focus {
    outline: none;
    background: none;
}

.iconContainer.selectedOne {
    color: #ffffff;
}

.selectedOne .MuiSvgIcon-root, .separatorSpan {
    color: #22f16e !important;
    filter: drop-shadow(0 0 10px #1DB454) !important;
}

.iconContainer p {
    margin: 0;
    margin-top: 2px;
    padding: 0;
}

.popularity_wrapper {
    margin-top: 5px;
    position: relative;
    width: 100%;
    height: 20px;
    border-radius: 3px;
}

.popularity_div_background {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    position: absolute;
}

.popularity_text {
    color: white;
    text-align: center;
    margin: 0;
    margin-top: 7px;
    margin-bottom: 10px;
}

.popularity_example_wrapper {
    color: white;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 3px;
}

.popularity_example_wrapper p {
    z-index: 2;
}

.popularity_example_wrapper img {
    width: 90px;
    height: 90px;
    aspect-ratio: 1/1;
    border-radius: 10px;
}

.divider {
    margin: 10px auto;
    background: rgba(255, 255, 255, 0.5);
    height: 2px;
    width: 100%;
}